import React, {useState} from 'react'
import { Container, Row, Image } from 'react-bootstrap'

import SignUpForm from './signup'

function SignUp() {

  //successfully registered
  const [registered, setRegistered] = useState(false);
  const [name, setName] = useState("stephen");

  const registrationForm = <div>
    <Row>
      <h2>Become a test user!</h2>
        </Row>
        <Row>
          <SignUpForm 
            setRegistered={setRegistered}
            setName={setName}
          />
        </Row>
      </div>

    const confirmation = <div style={{marginLeft: 'auto', marginRight: 'auto'}}>
      <Row>
        <div>
        <p>Hi {name},</p>
        <p>Thank you for becoming a test user! Please check your emails periodially, 
          as <a href='/'>Medial Health</a> will be sending more information about the product, and its
           testing and release dates.
        </p>
        </div>
        <div>
          <a href="https://www.instagram.com/medialhealthapp/" rel="noopener noreferrer" target="_blank">
            <Image style={{width: 20}} src={require('./../../assets/images/Instagram_AppIcon.png')}></Image>
            <span style={{paddingLeft: 10, color:'black', fontSize:13}}>@MedialHealthApp</span>
          </a>
        </div>
      </Row>
      <Row style={{marginTop: 20}}>
        <div>
          <p>Thanks,<br/>Medial Health</p>
        </div>
      </Row>
    </div>

    return (
      <Container>
        <div className="site-page">
          <div className="test-user-signup">
            {
              !registered? registrationForm : confirmation 
            }
          </div>
        </div>
        </Container>
      );
}

export default SignUp