import React from "react";

const License = (props) => {
  return (
    <div>
      <div>
        <div>
          <h2>END USER LICENSE AGREEMENT (EULA)</h2>
          <h6>Last updated May 23, 2020</h6>
          <p>
            Medial Health is licensed to You (End-User) by Medial Network LLC,
            located at 1813 SAINT JOHNS PL, Brooklyn, New York 11233, United
            States (hereinafter: Licensor), for use only under the terms of this
            License Agreement.
          </p>
          <p>
            By downloading the Application from the Apple AppStore, and any
            update thereto (as permitted by this License Agreement), You
            indicate that You agree to be bound by all of the terms and
            conditions of this License Agreement, and that You accept this
            License Agreement.
          </p>
          <p>
            The parties of this License Agreement acknowledge that Apple is not
            a Party to this License Agreement and is not bound by any provisions
            or obligations with regard to the Application, such as warranty,
            liability, maintenance and support thereof. Medial Network LLC, not
            Apple, is solely responsible for the licensed Application and the
            content thereof.
          </p>
          <p>
            This License Agreement may not provide for usage rules for the
            Application that are in conflict with the latest App Store Terms of
            Service. Medial Network LLC acknowledges that it had the opportunity
            to review said terms and this License Agreement is not conflicting
            with them.
          </p>
          <p>All rights not expressly granted to You are reserved.</p>
        </div>
        <div>
          <h5>1. THE APPLICATION</h5>
          <p>
            Medial Health (hereinafter: Application) is a piece of software
            created to give users complete control and access to their health
            data, and customized for Apple mobile devices. It is used to allow
            users to create a health diary to keep track of changes in their
            health, and that of their loved ones.
          </p>
        </div>
        <div>
          <h5>2. SCOPE OF LICENSE</h5>
          <p>
            2.1 You are given a non-transferable, non-exclusive,
            non-sublicensable license to install and use the Licensed
            Application on any Apple-branded Products that You (End-User) own or
            control and as permitted by the Usage Rules set forth in this
            section and the App Store Terms of Service, with the exception that
            such licensed Application may be accessed and used by other accounts
            associated with You (End-User, The Purchaser) via Family Sharing or
            volume purchasing.
          </p>
          <p>
            2.2 This license will also govern any updates of the Application
            provided by Licensor that replace, repair, and/or supplement the
            first Application, unless a separate license is provided for such
            update in which case the terms of that new license will govern.
          </p>
          <p>
            2.3 You may not share or make the Application available to third
            parties (unless to the degree allowed by the Apple Terms and
            Conditions, and with Medial Network LLC's prior written consent),
            sell, rent, lend, lease or otherwise redistribute the Application.
          </p>
          <p>
            2.4 You may not reverse engineer, translate, disassemble, integrate,
            decompile, integrate, remove, modify, combine, create derivative
            works or updates of, adapt, or attempt to derive the source code of
            the Application, or any part thereof (except with Medial Health
            LLC's prior written consent).
          </p>
          <p>
            2.5 You may not copy (excluding when expressly authorized by this
            license and the Usage Rules) or alter the Application or portions
            thereof. You may create and store copies only on devices that You
            own or control for backup keeping under the terms of this license,
            the App Store Terms of Service, and any other terms and conditions
            that apply to the device or software used. You may not remove any
            intellectual property notices. You acknowledge that no unauthorized
            third parties may gain access to these copies at any time.
          </p>
          <p>
            2.6 Violations of the obligations mentioned above, as well as the
            attempt of such infringement, may be subject to prosecution and
            damages.
          </p>
          <p>
            2.7 Licensor reserves the right to modify the terms and conditions
            of licensing.
          </p>
          <p>
            2.8 Nothing in this license should be interpreted to restrict
            third-party terms. When using the Application, You must ensure that
            You comply with applicable third-party terms and conditions.
          </p>
        </div>
        <div>
          <h5>3. TECHNICAL REQUIREMENTS</h5>
          <p>
            3.1 The Application requires a firmware version 1.0.0 or higher.
            Licensor recommends using the latest version of the firmware.
          </p>
          <p>
            3.2 Licensor attempts to keep the Application updated so that it
            complies with modified/new versions of the firmware and new
            hardware. You are not granted rights to claim such an update.
          </p>
          <p>
            3.3 You acknowledge that it is Your responsibility to confirm and
            determine that the app end-user device on which You intend to use
            the Application satisfies the technical specifications mentioned
            above.
          </p>
          <p>
            3.4 Licensor reserves the right to modify the technical
            specifications as it sees appropriate at any time.
          </p>
        </div>
        <div>
          <h5>4. MAINTENANCE AND SUPPORT</h5>
          <p>
            4.1 The Licensor is solely responsible for providing any maintenance
            and support services for this licensed Application. You can reach
            the Licensor at the email address listed in the App Store Overview
            for this licensed Application.
          </p>
          <p>
            4.2 Medial Network LLC and the End-User acknowledge that Apple has no
            obligation whatsoever to furnish any maintenance and support
            services with respect to the licensed Application.
          </p>
        </div>
        <div>
          <h5>5. USE OF DATA</h5>
          <p>
            You acknowledge that Licensor will be able to access and adjust Your
            downloaded licensed Application content and Your personal
            information, and that Licensor's use of such material and
            information is subject to Your legal agreements with Licensor and
            Licensor's privacy policy:&nbsp;
            <a href="https://www.medialhealth.app/privacy-policy">https://www.medialhealth.app/privacy-policy</a>.
          </p>
        </div>
        <div>
          <h5>6. LIABILITY</h5>
          <p>
            6.1 Licensor's responsibility in the case of violation of
            obligations and tort shall be limited to intent and gross
            negligence. Only in case of a breach of essential contractual duties
            (cardinal obligations), Licensor shall also be liable in case of
            slight negligence. In any case, liability shall be limited to the
            foreseeable, contractually typical damages. The limitation mentioned
            above does not apply to injuries to life, limb, or health.
          </p>
          <p>
            6.2 Licensor takes no accountability or responsibility for any
            damages caused due to a breach of duties according to Section 2 of
            this Agreement. To avoid data loss, You are required to make use of
            backup functions of the Application to the extent allowed by
            applicable third-party terms and conditions of use. You are aware
            that in case of alterations or manipulations of the Application, You
            will not have access to licensed Application.
          </p>
        </div>
        <div>
          <h5>7. WARRANTY</h5>
          <p>
            7.1 Licensor warrants that the Application is free of spyware,
            trojan horses, viruses, or any other malware at the time of Your
            download. Licensor warrants that the Application works as described
            in the user documentation.
          </p>
          <p>
            7.2 No warranty is provided for the Application that is not
            executable on the device, that has been unauthorizedly modified,
            handled inappropriately or culpably, combined or installed with
            inappropriate hardware or software, used with inappropriate
            accessories, regardless if by Yourself or by third parties, or if
            there are any other reasons outside of Medial Network LLC's sphere of
            influence that affect the executability of the Application.
          </p>
          <p>
            7.3 You are required to inspect the Application immediately after
            installing it and notify Medial Network LLC about issues discovered
            without delay by e-mail provided in Product Claims. The defect
            report will be taken into consideration and further investigated if
            it has been mailed within a period of sixty (60) days after
            discovery.
          </p>
          <p>
            7.4 If we confirm that the Application is defective, Medial Health
            LLC reserves a choice to remedy the situation either by means of
            solving the defect or substitute delivery.
          </p>
          <p>
            7.5 In the event of any failure of the Application to conform to any
            applicable warranty, You may notify the App-Store-Operator, and Your
            Application purchase price will be refunded to You. To the maximum
            extent permitted by applicable law, the App-Store-Operator will have
            no other warranty obligation whatsoever with respect to the App, and
            any other losses, claims, damages, liabilities, expenses and costs
            attributable to any negligence to adhere to any warranty.
          </p>
          <p>
            7.6 If the user is an entrepreneur, any claim based on faults
            expires after a statutory period of limitation amounting to twelve
            (12) months after the Application was made available to the user.
            The statutory periods of limitation given by law apply for users who
            are consumers.
          </p>
        </div>
        <div>
          <h5>8. PRODUCT CLAIMS</h5>
          <p>
            Medial Network LLC and the End-User acknowledge that Medial Health
            LLC, and not Apple, is responsible for addressing any claims of the
            End-User or any third party relating to the licensed Application or
            the End-User’s possession and/or use of that licensed Application,
            including, but not limited to:
          </p>
          <p>(i) product liability claims;</p>
          <p>
            (ii) any claim that the licensed Application fails to conform to any
            applicable legal or regulatory requirement; and
          </p>
          <p>
            (iii) claims arising under consumer protection, privacy, or similar
            legislation, including in connection with Your Licensed
            Application’s use of the HealthKit and HomeKit.
          </p>
        </div>

        <div>
          <h5>9. LEGAL COMPLIANCE</h5>
          <p>
            You represent and warrant that You are not located in a country that
            is subject to a U.S. Government embargo, or that has been designated
            by the U.S. Government as a "terrorist supporting" country; and that
            You are not listed on any U.S. Government list of prohibited or
            restricted parties.
          </p>
        </div>

        <div>
          <h5>10. CONTACT INFORMATION </h5>
          <p>
            For general inquiries, complaints, questions or claims concerning
            the licensed Application, please contact:
          </p>
          <p>
            Medial Network LLC
            <br />
            1813 SAINT JOHNS PL
            <br />
            Brooklyn, NY 11233
            <br />
            United States
            <br />
            <a href="mailto:support@medialhealth.app">support@medialhealth.app</a>
            <br />
          </p>
        </div>
        <div>
          <h5>11. TERMINATION</h5>
          <p>
            The license is valid until terminated by Medial Network LLC or by
            You. Your rights under this license will terminate automatically and
            without notice from Medial Network LLC if You fail to adhere to any
            term(s) of this license. Upon License termination, You shall stop
            all use of the Application, and destroy all copies, full or partial,
            of the Application.
          </p>
        </div>
        <div>
          <h5>12. THIRD-PARTY TERMS OF AGREEMENTS AND BENEFICIARY</h5>
          <p>
            Medial Network LLC represents and warrants that Medial Network LLC
            will comply with applicable third-party terms of agreement when
            using licensed Application.
          </p>
          <p>
            In Accordance with Section 9 of the "Instructions for Minimum Terms
            of Developer's End-User License Agreement," Apple and Apple's
            subsidiaries shall be third-party beneficiaries of this End User
            License Agreement and - upon Your acceptance of the terms and
            conditions of this license agreement, Apple will have the right (and
            will be deemed to have accepted the right) to enforce this End User
            License Agreement against You as a third-party beneficiary thereof.
          </p>
        </div>
        <div>
          <h5>13. INTELLECTUAL PROPERTY RIGHTS</h5>
          <p>
            Medial Network LLC and the End-User acknowledge that, in the event of
            any third-party claim that the licensed Application or the
            End-User's possession and use of that licensed Application infringes
            on the third party's intellectual property rights, Medial Health
            LLC, and not Apple, will be solely responsible for the
            investigation, defense, settlement and discharge or any such
            intellectual property infringement claims.
          </p>
        </div>
        <div>
          <h5>14. APPLICABLE LAW</h5>
          <p>
            This license agreement is governed by the laws of the State of New
            York excluding its conflicts of law rules.
          </p>
        </div>
        <div>
          <h5>15. MISCELLANEOUS</h5>
          <p>
            15.1 If any of the terms of this agreement should be or become
            invalid, the validity of the remaining provisions shall not be
            affected. Invalid terms will be replaced by valid ones formulated in
            a way that will achieve the primary purpose.
          </p>
          <p>
            15.2 Collateral agreements, changes and amendments are only valid if
            laid down in writing. The preceding clause can only be waived in
            writing.
          </p>
        </div>
      </div>
    </div>
  );
};

export default License;
