import React from "react";
import OwlCarousel from "react-owl-carousel2";
import {Image} from 'react-bootstrap';
import 'font-awesome/css/font-awesome.min.css'; 
import ImageSrc from './ImageSrc';

class OwlSlider extends React.Component {
    render () {
     
        const options = {
          nav: true,
          rewind: true,
          autoplay: true,
          dots: false,
          navText: ['<i class="fa fa-angle-left" aria-hidden="true"></i>','<i class="fa fa-angle-right" aria-hidden="true"></i>'],
          responsive:{
            0:{
                items:1,
                margin: 20,
            },
            600:{
                items:2,
                margin: 20,
            },
            1024:{
                items:2,
                margin: 20,
            },
            1200: {
              items:3,
            },
            1400: {
              margin:50,
              
            }
        }          
        };  

        const events = {
          onDragged: function(event) {
            console.log('');
          },
          onChanged: function(event) {
            console.log('');
          }
        }
        const images = ImageSrc.map(image => {
          return <Image className={'image-carousel'} key={image.id} src={require(`${image.src}`)}/>
        });
       
        return (
          <React.Fragment>
            <OwlCarousel ref="car" options={options} events={events}>
              {images}
            </OwlCarousel>
          </React.Fragment>
        );
    }
}



export default OwlSlider;
