const ImageSrc = [  
    { id: 1, src: './images/screen-dash.jpg'},
    { id: 2, src: './images/screen-diary.jpg'},
    { id: 3, src: './images/screen-diary-vitals.jpg'},        
    { id: 4, src: './images/screen-dash-detail.jpg'},
    // { id: 1, src: './images/screen-dash-relationships.jpg'},
]

export default ImageSrc;

