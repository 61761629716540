import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
  } from "react-router-dom";

import './assets/styles/App.css'

import LandingPage from './components/LandingPage'
import SignUp from './components/Signup'
import NavBar from './components/NavBar'
import PrivacyPolicy from './views/policies/privacy'
import EULA from './views/policies/license';


function App() {
    return (
        // Move to routes.js file later
      <Router>
        <div>
            <NavBar/>
          <Switch>
            <Route exact path="/">
              <LandingPage />
            </Route>
            {/* <Route path="/get-involved">
              <SignUp />
            </Route> */}
            <Route path="/privacy-policy">
              <PrivacyPolicy />
            </Route>
            <Route path="/eula">
              <EULA />
            </Route>
            <Redirect from='*' to='/' />
          </Switch>
        </div>
      </Router>
    );
  }

export default App;

