import React from 'react'
import { Container } from 'react-bootstrap'

import Policy from './policy'


const PrivacyPolicy = (props) => {
    return <Container>
        <div className="site-page">
            <div>
                <Policy />
            </div>
        </div>
    </Container>;
}

export default PrivacyPolicy