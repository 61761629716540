import React from "react";

const Policy = (props) => {
  return (
    <div className="privacy-policy">
      <div>
        <div>
          <h2>PRIVACY POLICY</h2>
          <h6>Last updated May 13, 2020</h6>
          <p>
            Thank you for choosing to be part of our community at Medial Health
            (“Company”, “<b>we</b>”, “<b>us</b>”, or “<b>our</b>”). We are committed to protecting
            your personal information and your right to privacy. If you have any
            questions or concerns about our policy, or our practices with
            regards to your personal information, please contact us at{" "}
            <a href="mailto: info@medialhealth.app">info@medialhealth.app</a>.
          </p>
          <p>
            {" "}
            When you visit our mobile application, and use our services, you
            trust us with your personal information. We take your privacy very
            seriously. In this privacy policy, we seek to explain to you in the
            clearest way possible what information we collect, how we use it and
            what rights you have in relation to it. We hope you take some time
            to read through it carefully, as it is important. If there are any
            terms in this privacy policy that you do not agree with, please
            discontinue use of our Apps and our services.
          </p>
          <p>
            This privacy policy applies to all information collected through our
            mobile application, ("<b>Apps</b>"), and/or any related services, sales,
            marketing or events (we refer to them collectively in this privacy
            policy as the "<b>Services</b>").
          </p>
          <p>
            <b>Please read this privacy policy carefully as it will help you make
            informed decisions about sharing your personal information with us.</b>
          </p>
        </div>
        <br/>
        <div>
          <h5>TABLE OF CONTENTS</h5>
          <ol>
            <li>
              <a href="#collect">WHAT INFORMATION DO WE COLLECT?</a>
            </li>
            <li>
              <a href="#use">HOW DO WE USE YOUR INFORMATION?</a>
            </li>
            <li>
              <a href="#shared">WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a>
            </li>
            <li>
              <a href="#keep">HOW LONG DO WE KEEP YOUR INFORMATION?</a>
            </li>
            <li>
              <a href="#safety">HOW DO WE KEEP YOUR INFORMATION SAFE?</a>
            </li>
            <li>
              <a href="#rights">WHAT ARE YOUR PRIVACY RIGHTS?</a>
            </li>
            <li>
              <a href="#breach">DATA BREACH</a>
            </li>
            <li>
              <a href="#controls">CONTROLS FOR DO-NOT-TRACK FEATURES</a>
            </li>
            <li>
              <a href="#california">
                DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
              </a>
            </li>
            <li>
              <a href="#updates">DO WE MAKE UPDATES TO THIS POLICY?</a>
            </li>
            <li>
              <a href="#contact-us">
                HOW CAN YOU CONTACT US ABOUT THIS POLICY?
              </a>
            </li>
          </ol>
        </div>

        <div id="collect">
          <h4>1. WHAT INFORMATION DO WE COLLECT?</h4>
          <h5>Personal information you disclose to us</h5>
          <p>
            <b>In Short:</b> We collect personal information that you provide to
            us.
          </p>
          <p>
            We collect personal information that you voluntarily provide to us
            when registering at the Apps, expressing an interest in obtaining
            information about us or our products and services, when
            participating in activities on the Apps (such as posting messages in
            our online forums or entering competitions, contests or giveaways)
            or otherwise contacting us.
          </p>
          <p>
            The personal information that we collect depends on the context of
            your interactions with us and the Apps, the choices you make and the
            products and features you use. The personal information we collect
            can include the following:
          </p>

          <p>
            <b>Publicly Available Personal Information.</b> We collect first name,
            maiden name, last name, and nickname; email addresses; phone
            numbers; family member names and their related information; and
            other similar data.
          </p>

          <p>
          <b>Personal Information Provided by You.</b> We collect data about health,
            DNA, medical records, FitBit, and similar apps; app usage;
            passwords; and other similar data.
          </p>

          <p>
            All personal information that you provide to us must be true,
            complete and accurate, and you must notify us of any changes to such
            personal information.
          </p>
          <br />
          <h6>Information automatically collected</h6>
          <p>
            <b>In Short:</b> Some information — such as IP address and/or
            browser and device characteristics — is collected automatically when
            you visit our Apps.
          </p>
          <p>
            We automatically collect certain information when you visit, use or
            navigate the Apps. This information does not reveal your specific
            identity (like your name or contact information) but may include
            device and usage information, such as your IP address, browser and
            device characteristics, operating system, language preferences,
            referring URLs, device name, country, location, information about
            how and when you use our Apps and other technical information. This
            information is primarily needed to maintain the security and
            operation of our Apps, and for our internal analytics and reporting
            purposes.
          </p>
          <br />
          <h6>Information collected through our Apps</h6>
          <p>
            <b>In Short:</b> We may collect information regarding your mobile
            device, push notifications, when you use our apps.
          </p>
          <p>
            If you use our Apps, we may also collect the following information:
          </p>
          <p>
            Mobile Device Access. We may request access or permission to certain
            features from your mobile device, including your mobile device's
            camera, storage, and other features. If you wish to change our
            access or permissions, you may do so in your device's settings.
          </p>
          <p>
            Push Notifications. We may request to send you push notifications
            regarding your account or the mobile application. If you wish to
            opt-out from receiving these types of communications, you may turn
            them off in your device's settings.
          </p>
        </div>

        <div id="use">
          <h4>2. HOW DO WE USE YOUR INFORMATION?</h4>
          <p>
            <b>In Short:</b> We process your information for purposes based on
            legitimate business interests, the fulfillment of our contract with
            you, compliance with our legal obligations, and/or your consent.
          </p>
          <p>
            We use personal information collected via our Apps for a variety of
            business purposes described below. We process your personal
            information for these purposes in reliance on our legitimate
            business interests, in order to enter into or perform a contract
            with you, with your consent, and/or for compliance with our legal
            obligations. We indicate the specific processing grounds we rely on
            next to each purpose listed below.
          </p>
          <p>We use the information we collect or receive:</p>
          <ul>
            <li>
              To facilitate account creation and logon process. If you choose to
              link your account with us to a third party account (such as your
              Google or Facebook account), we use the information you allowed us
              to collect from those third parties to facilitate account creation
              and logon process for the performance of the contract.
            </li>
            <li>
              To send you marketing and promotional communications. We and/or
              our third party marketing partners may use the personal
              information you send to us for our marketing purposes, if this is
              in accordance with your marketing preferences. You can opt-out of
              our marketing emails at any time (see the "WHAT ARE YOUR PRIVACY
              RIGHTS" below).
            </li>
            <li>
              To send administrative information to you. We may use your
              personal information to send you product, service and new feature
              information and/or information about changes to our terms,
              conditions, and policies.
            </li>
            <li>
              To enable user-to-user communications. We may use your information
              in order to enable user-to-user communications with each user's
              consent.
            </li>
            <li>
              To enforce our terms, conditions and policies for Business
              Purposes, Legal Reasons and Contractual.
            </li>
            <li>
              To respond to legal requests and prevent harm. If we receive a
              subpoena or other legal request, we may need to inspect the data
              we hold to determine how to respond.
            </li>
            <li>
              To manage user accounts. We may use your information for the
              purposes of managing our account and keeping it in working order.
            </li>
            <li>
              To respond to user inquiries/offer support to users. We may use
              your information to respond to your inquiries and solve any
              potential issues you might have with the use of our Services.
            </li>
            <li>
              For other Business Purposes. We may use your information for other
              Business Purposes, such as data analysis, identifying usage
              trends, determining the effectiveness of our promotional campaigns
              and to evaluate and improve our Apps, products, marketing and your
              experience. We may use and store this information in aggregated
              and anonymized form so that it is not associated with individual
              end users and does not include personal information. We will not
              use identifiable personal information without your consent.
            </li>
          </ul>
        </div>
        <div id="shared">
          <h4>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h4>
          <p>
            <b>In Short:</b> We only share information with your consent, to
            comply with laws, to provide you with services, to protect your
            rights, or to fulfill business obligations.
          </p>
          <p>
            We may process or share data based on the following legal basis:
          </p>
          <ul>
            <li>
              Consent: We may process your data if you have given us specific
              consent to use your personal information in a specific purpose.
            </li>
            <li>
              Legitimate Interests: We may process your data when it is
              reasonably necessary to achieve our legitimate business interests.
            </li>
            <li>
              Performance of a Contract: Where we have entered into a contract
              with you, we may process your personal information to fulfill the
              terms of our contract.
            </li>
            <li>
              Legal Obligations: We may disclose your information where we are
              legally required to do so in order to comply with applicable law,
              governmental requests, a judicial proceeding, court order, or
              legal process, such as in response to a court order or a subpoena
              (including in response to public authorities to meet national
              security or law enforcement requirements).
            </li>
            <li>
              Vital Interests: We may disclose your information where we believe
              it is necessary to investigate, prevent, or take action regarding
              potential violations of our policies, suspected fraud, situations
              involving potential threats to the safety of any person and
              illegal activities, or as evidence in litigation in which we are
              involved.
            </li>
          </ul>
          <p>
            More specifically, we may need to process your data or share your
            personal information in the following situations:
          </p>
          <ul>
            <li>
              Business Transfers. We may share or transfer your information in
              connection with, or during negotiations of, any merger, sale of
              company assets, financing, or acquisition of all or a portion of
              our business to another company.
            </li>
            <li>
              Third-Party Advertisers. We may use third-party advertising
              companies to serve ads when you visit the Apps. These companies
              may use information about your visits to our Website(s) and other
              websites that are contained in web cookies and other tracking
              technologies in order to provide advertisements about goods and
              services of interest to you.
            </li>
            <li>
              Other Users. When you share personal information (for example, by
              posting comments, contributions or other content to the Apps) or
              otherwise interact with public areas of the Apps, such personal
              information may be viewed by all users and may be publicly
              distributed outside the Apps in perpetuity. Similarly, other users
              will be able to view descriptions of your activity, communicate
              with you within our Apps, and view your profile.
            </li>
          </ul>
        </div>
        <div id="keep">
          <h4>4. HOW LONG DO WE KEEP YOUR INFORMATION?</h4>
          <p>
            <b>In Short:</b> We keep your information for as long as necessary
            to fulfill the purposes outlined in this privacy policy unless
            otherwise required by law.
          </p>

          <p>
            e will only keep your personal information for as long as it is
            necessary for the purposes set out in this privacy policy, unless a
            longer retention period is required or permitted by law (such as
            tax, accounting or other legal requirements). No purpose in this
            policy will require us keeping your personal information for longer
            than the period of time in which users have an account with us.
          </p>

          <p>
            When we have no ongoing legitimate business need to process your
            personal information, we will either delete or anonymize it, or, if
            this is not possible (for example, because your personal information
            has been stored in backup archives), then we will securely store
            your personal information and isolate it from any further processing
            until deletion is possible.
          </p>
        </div>
        <div id="safety">
          <h4>5. HOW DO WE KEEP YOUR INFORMATION SAFE?</h4>
          <p>
            <b>In Short:</b> We aim to protect your personal information through
            a system of organizational and technical security measures. We have
            implemented appropriate technical and organizational security
            measures designed to protect the security of any personal
            information we process. However, please also remember that we cannot
            guarantee that the internet itself is 100% secure. Although we will
            do our best to protect your personal information, transmission of
            personal information to and from our Apps is at your own risk. You
            should only access the services within a secure environment.
          </p>
        </div>
        <div id="rights">
          <h4>6. WHAT ARE YOUR PRIVACY RIGHTS?</h4>
          <p>
            <b>In Short:</b> You may review, change, or terminate your account
            at any time.
          </p>
          <p>
            If you are resident in the European Economic Area and you believe we
            are unlawfully processing your personal information, you also have
            the right to complain to your local data protection supervisory
            authority. You can find their contact details here:
            http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
          </p>
          <p>
            If you have questions or comments about your privacy rights, you may
            email us at{" "}
            <a href="mailto: info@medialhealth.app">info@medialhealth.app</a>.
          </p>
          <h6>Account Information</h6>
          <p>
            If you would at any time like to review or change the information in
            your account or terminate your account, you can:
          </p>
          <p>■ Log into your account settings and update your user account.</p>
          <p>■ Contact us using the contact information provided.</p>
          <p>
            Upon your request to terminate your account, we will deactivate or
            delete your account and information from our active databases.
            However, some information may be retained in our files to prevent
            fraud, troubleshoot problems, assist with any investigations,
            enforce our Terms of Use and/or comply with legal requirements.
          </p>
          <p>
            Opting out of email marketing: You can unsubscribe from our
            marketing email list at any time by clicking on the unsubscribe link
            in the emails that we send or by contacting us using the details
            provided below. You will then be removed from the marketing email
            list – however, we will still need to send you service-related
            emails that are necessary for the administration and use of your
            account. To otherwise opt-out, you may:
          </p>
          <p> ■ Access your account settings and update preferences.</p>
          <p>
            {" "}
            ■ Note your preferences when you register an account with the site.
          </p>
        </div>
        <div id="breach">
          <h4>7. DATA BREACH</h4>
          <p>
            A privacy breach occurs when there is unauthorized access to or
            collection, use, disclosure or disposal of personal information. You
            will be notified about data breaches when Medial Health believes you
            are likely to be at risk or serious harm. For example, a data breach
            may be likely to result in serious financial harm or harm to your
            mental or physical well-being. In the event that Medial Health
            becomes aware of a security breach which has resulted or may result
            in unauthorized access, use or disclosure of personal information
            Medial Health will promptly investigate the matter and notify the
            applicable Supervisory Authority not later than 72 hours after
            having become aware of it, unless the personal data breach is
            unlikely to result in a risk to the rights and freedoms of natural
            persons.
          </p>
        </div>
        <div id="controls">
          <h4>8. CONTROLS FOR DO-NOT-TRACK FEATURES</h4>
          <p>
            Most web browsers and some mobile operating systems and mobile
            applications include a Do-Not-Track (“DNT”) feature or setting you
            can activate to signal your privacy preference not to have data
            about your online browsing activities monitored and collected. No
            uniform technology standard for recognizing and implementing DNT
            signals has been finalized. As such, we do not currently respond to
            DNT browser signals or any other mechanism that automatically
            communicates your choice not to be tracked online. If a standard for
            online tracking is adopted that we must follow in the future, we
            will inform you about that practice in a revised version of this
            privacy policy.
          </p>
        </div>
        <div id="california">
          <h4>9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h4>
          <p>
            <b>In Short:</b> Yes, if you are a resident of California, you are
            granted specific rights regarding access to your personal
            information.
          </p>
          <p>
            California Civil Code Section 1798.83, also known as the “Shine The
            Light” law, permits our users who are California residents to
            request and obtain from us, once a year and free of charge,
            information about categories of personal information (if any) we
            disclosed to third parties for direct marketing purposes and the
            names and addresses of all third parties with which we shared
            personal information in the immediately preceding calendar year. If
            you are a California resident and would like to make such a request,
            please submit your request in writing to us using the contact
            information provided below.
          </p>
          <p>
            If you are under 18 years of age, reside in California, and have a
            registered account with the Apps, you have the right to request
            removal of unwanted data that you publicly post on the Apps. To
            request removal of such data, please contact us using the contact
            information provided below, and include the email address associated
            with your account and a statement that you reside in California. We
            will make sure the data is not publicly displayed on the Apps, but
            please be aware that the data may not be completely or
            comprehensively removed from our systems.
          </p>
        </div>
        <div id="updates">
          <h4>10. DO WE MAKE UPDATES TO THIS POLICY?</h4>
          <p>
            <b>In Short:</b> Yes, we will update this policy as necessary to
            stay compliant with relevant laws.
          </p>
          <p>
            We may update this privacy policy from time to time. The updated
            version will be indicated by an updated “Revised” date and the
            updated version will be effective as soon as it is accessible. If we
            make material changes to this privacy policy, we may notify you
            either by prominently posting a notice of such changes or by
            directly sending you a notification. We encourage you to review this
            privacy policy frequently to be informed of how we are protecting
            your information.
          </p>
        </div>
        <div id="contact-us">
          <h4>11. HOW CAN YOU CONTACT US ABOUT THIS POLICY?</h4>
          <p>
            If you have questions or comments about this policy, you may email
            us at{" "}
            <a href="mailto: info@medialhealth.app">info@medialhealth.app</a> or
            by post to:
          </p>
          <div>
            <span>Medial Health </span>
            <br />
            <span>1813 Saint Johns PL</span>
            <br />
            <span>1FL</span>
            <br />
            <span>Brooklyn, NY 11233</span>
            <br />
            <span>United States</span>
            <br />
            <br />
          </div>
        </div>
        <div>
          <h4>
            HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
          </h4>
          <p>
            Based on the laws of some countries, you may have the right to
            request access to the personal information we collect from you,
            change that information, or delete it in some circumstances. To
            request to review, update, or delete your personal information,
            please visit:{" "}
            <a href="https://www.medialhealth.app." target="_blank">
              https://www.medialhealth.app.
            </a>{" "}
            We will respond to your request within 30 days.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Policy;
