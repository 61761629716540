import React from 'react'
import { Container } from 'react-bootstrap'
import License from './license'


const PrivacyPolicy = (props) => {
    return <Container>
        <div className="site-page">
            <div>
                <License />
            </div>
        </div>
    </Container>;
}

export default PrivacyPolicy