import React from 'react'
import { Navbar, Image } from 'react-bootstrap';

export default function NavBar() {

    return (
        <Navbar className="app-nav" variant="light" >
            <Navbar.Brand href="/">
                <Image className="nav-logo" src={require('./../../assets/images/healthLogo.png')} />
                {' '}
            Medial Health
            </Navbar.Brand>
        </Navbar>
    );
}
