import React, {Component} from 'react';
import OwlSlider from './OwlSlider'
import {Container,Row,Col,Image} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

class LandingPage extends Component {    
   constructor() {
      super();
      this.state = {
         screenWidth: 0,
      }
      this.updateWindowDimensions = this.updateWindowDimensions.bind(this)      
   }
   componentDidMount() {
      this.updateWindowDimensions()
      window.addEventListener('resize', this.updateWindowDimensions)
   }
   componentWillUnmount() {
      window.removeEventListener('resize', this.updateWindowDimensions)
   }
   
   updateWindowDimensions() {
      this.setState({ screenWidth: window.innerWidth})
   }

   renderAppDownloadBtn =()=> {
      return (
         <div className={'downloadNowWrapper'}>
            <a href='https://apps.apple.com/us/app/id1513708295'> 
               <Image alt='Download on the App Store' style={{width: '100%', height: 'auto'}} src={require('./../../assets/images/app-store-badge.svg')} /> 
            </a>
            <a href='https://play.google.com/store/apps/details?id=com.media_health_app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                  <Image style={{width: 159, height: 60}} alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
               </a>
         </div>
      )
   }

    render () {
       const {screenWidth} = this.state;
       return(
        <div className={'landingMainWrapper'}>
           <span className={'blueOverLay'}></span>
           <Container  className={'mainContainer'} fluid>
               <div className={'headerWrapper'}>
                  <Row>
                     <Col xs={8} sm={5}>
                        <a href={'/'} className={'brandLogo'}>
                           <Image src={require('./../../assets/images/Logo.png')} /> 
                        </a>
                     </Col>
                     <Col xs={4} sm={7} className={'btnRightCol'}>
                        <div className={'headerBtnWrapper'}>
                           {/* <a className={'sign-up-link'} href={'/get-involved'}><h6>Become a test user!</h6></a> */}
                           <a className={'sign-up-link'} href={'/privacy-policy'}><h6>Privacy Policy</h6></a>
                           <a className={'sign-up-link'} href={'/eula'}><h6>End User License Agreement</h6></a>
                        </div>  
                     </Col>
                  </Row>
               </div>
               <div className={'mainContentWrapper'}>
                  {screenWidth < 767 ? this.renderAppDownloadBtn():null}
                  <Row>
                     <Col className={'aboutMedialHealthCol'} xs={12} sm={4}>
                        <div className={'aboutMedialHealthWrapper'}>
                           <h1 className={'title'}><span>About</span> Medial Health</h1>
                           <h4 className={'landing-subtitle'}>Take charge of your health!</h4>
                           <p>
                              The Medial health app allows you to manage your health and that of your loved ones. 
                              It allows you to keep track of vitals, symptoms, allergies and much more, in a 
                              centralized location. The app also makes it easy for you to stay up to date when 
                              changes occur, by pushing notifications to your device.
                           </p>
                           <p>
                              Medial Health gives you the ability to grant or restrict access to all or a subset 
                              of your data based on the individual or institution. With Medial Health, you are in full 
                              control of who has access to your medical data!
                           </p>
                           {screenWidth > 767 ? this.renderAppDownloadBtn():null}
                        </div>
                     </Col>
                     <Col className={'carouselCol'} xs={12} sm={8}>
                        <OwlSlider/>
                     </Col>
                  </Row>
                  <br></br>
                  <br></br>
               </div>
           </Container> 
        </div>
       ); 
    }
}

export default LandingPage;